const data = [
    {
      id: 1,
      question: "What do you need to start working on my project?",
      answer: "It mostly depends on the type of project. But in general, you need a fair idea of what you want, and then we can take it from there."
    },
    {
      id: 2,
      question: "How long will my project take to complete?",
      answer: "This depends on the complexity of the project, your available, and your payment. Once you have this sorted out, we will give you a completion date."
    },
    {
      id: 5,
      question: "Is hosting and domain registration included in your pricing?",
      answer: "No! Hosting and domain management are taken care of separately. You can pay for it or take care of it on your own if you know how."
    },
    {
      id: 4,
      question: "How much do you charge for an average website or app?",
      answer: "Once again, this depends. But our prices are affordable, so you're good."
    },
    {
      id: 3,
      question: "What's your payment plan?",
      answer: "Payment is divided into 3. An initial 30% upfront payment to get me started once all the details of the projec are set, 40% once the project is completed, and 30% once the project is delivered."
    },
    {
      id: 6,
      question: "What if the project doesn't turn out good?",
      answer: "You will be a part of the process from the start. You will not know how the whole project is going until the end, and we will make sure to give you the best."
    }
  ]

  export default data