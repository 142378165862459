import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
// import {AiOutlineDribbble} from 'react-icons/ai'
import {AiFillFacebook} from 'react-icons/ai'
// import {AiFillGithub} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'
import {FaWhatsapp} from 'react-icons/fa'


const data = [
    {id: 4, link: 'https://wa.me/+447442621648/', icon: <FaWhatsapp/>},
    {id: 1, link: 'https://www.linkedin.com/company/int-elligence-tech/', icon: <AiFillLinkedin/>},
    {id: 2, link: 'https://www.instagram.com/intelligence.co.uk/', icon: <AiOutlineInstagram/>},
    {id: 3, link: 'https://twitter.com/_int_elligence', icon: <AiOutlineTwitter/>},
    // {id: 5, link: 'https://github.com', icon: <AiFillGithub/>}

]

export default data