import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'
import jaser from '../../assets/jaser.jpg'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '5+ Years Working'},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: '50+ Completed'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: '10+ happy clients'},
    // {id: 4, icon: <FaAward/>, title: 'Experience', desc: '5+ Years Working'},
    // {id: 5, icon: <TbBooks/>, title: 'Projects', desc: '50+ Completed'},
    // {id: 6, icon: <BiHappyHeartEyes/>, title: 'Clients', desc: '10+ happy clients'}
    ]



    export default data;