import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import contacts from './data'; // Import contacts data
import './contact.css'; // Import contact stylesheet

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    subject: '',
    message: ''
  });

  // const [messageSent, setMessageSent] = useState(false);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mvx2tav', 'template_8e8yo54', e.target, 'TxH2tykmM5GTz_8WO')
      .then((result) => {
        console.log(result.text);
        alert('Your message has been sent!');
        // Optionally, show a success message or redirect to a thank you page
      }, (error) => {
        console.log(error.text);
        // Optionally, show an error message to the user
      });
  };

  return (
    <section id="contact">
      <h2>Get In Touch</h2>
      <p className='para'>Shoot us a message or click any of the links below!</p>

     

      <div className="form__container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange}  />
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
        {/* {messageSent && <p>Your message has been sent!</p>} Display message if messageSent is true */}

      </div>
      <div className="container contact__container" data-aos="fade-up">
        {
          contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener noreferrer">{contact.icon}</a>)
        }
      </div>
    </section>
  );
};

export default ContactForm;






// import contacts from './data'
// import './contact.css'




// const Contact = () => {
//   return (
//     <section id="contact">
//       <h2>Get In Touch</h2>
//       <p>
//         Shoot us a message via any of the links below!
//       </p>

//   <div className='form__container'>
//     <form action="/order" method="post">
//         <div class="form-group">
//           <label for="name">Name:</label>
//           <input type="text" id="name" name="name" required/>
//         </div>
//         <div class="form-group">
//           <label for="mobile">Mobile:</label>
//           <input type="text" id="mobile" name="mobile" required/>
//         </div>
//         <div class="form-group">
//           <label for="email">Email:</label>
//           <input type="text" id="email" name="Email" required/>
//         </div>
//         <div class="form-group">
//           <label for="subject">Subject</label>
//           <input type="text" id="subject" name="Subject" required/>
//         </div>
//         <div class="form-group">
//           <label for="message">Message</label>
//           <textarea type="text" id="message" name="Message" rows="4" cols="50"required/>
//         </div>
//         <button type="submit" class="submit-btn">Submit</button>
//       </form>
//     </div>

//       <div className="container contact__container" data-aos="fade-up">
//         {
//           contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener noreferrer">{contact.icon}</a>)
//         }
//       </div>


    
//     </section>
//   )
// }

// export default Contact