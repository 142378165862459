// import Image1 from '../../assets/project1.jpg'
import Image1 from '../../assets/portfolio10.jpg'
// import Image2 from '../../assets/project2.jpg'
import Image2 from '../../assets/portfolio11.jpg'
// import Image3 from '../../assets/project3.jpg'
import Image3 from '../../assets/portfolio12.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/portfolio6.jpg'
import Image6 from '../../assets/project3.jpg'
import Image7 from '../../assets/project1.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project2.jpg'

const data = [
    {
        id: 1,
        category: 'Web App',
        image: Image1,
        title: "Hotels, Tours and Flight Booking Website, India",
        // desc: "This is a complete hotels, tours, flights, and car booking website with an admin dashboard. The dashboard feature allows the admin to manage the inventory, which also enables people to sign up as partners and list their services.",
        desc: "This is a complete hotels, tours, flights, and car booking website that offers various travel packages and services, with a visually appealing layout and a comprehensive search function for easy navigation. The dashboard feature allows the admin to manage the inventory, which also enables people to sign up as partners and list their services.",
        // demo: 'http://egatortutorials.com',
        github: 'https://naaztourism.com/'
    },
    {
        id: 2,
        category: 'Web App',
        image: Image2,
        title: "eCommerce Website for Beauty Products, Dubai",
        // desc: "Build and deployed beauty products eCommerce website for the famous Lebanese makeup artist Jolle Mardinian. This is a comprehensive eCommerce solution for expanding online enterprises and selling products.",
        desc: "The eCommerce website for the famous makeup artist Joelle Mardinian is a sleek, user-friendly platform with a clean design, personalized recommendations, and secure payment processing. It features premium cosmetics, skincare, and a range of functionalities to enhance the shopping experience, including customer reviews and social media integration.",
        // demo: 'http://egatortutorials.com',
        github: 'https://joelleparis.com/'
    },
    {
        id: 3,
        category: 'Web App',
        image: Image3,
        title: "Portfolio Website for International Consulting Company, Italy",
        desc: "This portfolio website is a visually stunning and user-friendly platform that showcases the firm's diverse range of consulting services and expertise. The website features a clean and modern design that is optimized for fast loading and easy navigation, ensuring a seamless user experience for visitors.",
        // demo: 'http://egatortutorials.com',
        github: 'https://italintechnologies.com/'
    },
    {
        id: 4,
        category: 'Desktop App',
        image: Image4,
        title: "Supermarket Sales Management System, India",
        desc: "This is a desktop application that streamlines sales management for supermarkets. It tracks sales, inventory, and customer data efficiently. The user-friendly interface allows staff to manage transactions, inventory levels, and generate reports. The system alerts when inventory levels fall below a threshold to prevent stockouts.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 5,
        category: 'Desktop App',
        image: Image5,
        title: "Construction Equipment Rental Management System, India",
        desc: "The desktop application tracks equipment inventory, rental orders, customer information, and finances, allowing users to make informed decisions and manage inventory effectively. It also manages reservations, contracts, and billing, and includes user management for controlled access and different levels of responsibility.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 6,
        category: 'Desktop App',
        image: Image6,
        title: "Gym Dashboard & Members Management",
        desc: "This application is a comprehensive desktop solution. With features such as member registration and management, workout tracking, payment processing, and reporting, gym managers can efficiently manage their facilities and provide excellent service to their members. The application's user-friendly interface and robust functionality make it an indispensable tool for any gym or fitness center.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 7,
        category: 'Web App',
        image: Image7,
        title: "Complete Hospital Website",
        desc: "The hospital website is a user-friendly platform that provides patients with easy access to important information about the hospital's services and facilities. It features a modern design with clear navigation and responsive functionality for seamless browsing on all devices. The website also includes online appointment scheduling and a patient portal for convenient access to medical records and communication with healthcare providers.        ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 8,
        category: 'Web App',
        image: Image8,
        title: "Blog App/Website with Admin Panel",
        desc: "This user-friendly platform allows bloggers to create and manage their own blogs. The website includes an intuitive interface that makes it easy for bloggers to write and publish posts, manage comments, and customise their blog's design. The Admin Panel provides an easy-to-use backend for managing the app's or website's content and settings, enabling site administrators to monitor user activity and maintain site security.",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    },
    {
        id: 9,
        category: 'Web App',
        image: Image9,
        title: "Photography Portfolio Website",
        desc: "This photography portfolio website showcases stunning visuals captured by talented photographers. The website features a sleek and modern design, with easy navigation and a focus on the photographer's work. It is optimized for fast loading and mobile responsiveness, providing a seamless user experience across all devices. ",
        // demo: 'http://egatortutorials.com',
        // github: 'https://github.com/egattor'
    }
]


export default data