import {HiOutlineMail} from 'react-icons/hi'
import {RiMessengerLine} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:contact@int-elligence.co.uk'},
    // {id: 2, icon: <RiMessengerLine/>, link: 'https://www.messenger.com/'},
    {id: 2, icon: <FaInstagram/>, link: 'https://www.instagram.com/intelligence.co.uk/'},
    {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/+447442621648'}
]


// alternative whatsApp link
// https://wa.me/yournumber
// https://api.whatsapp.com/send/?phone=%2B233557097546


export default data