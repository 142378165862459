import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'
import {FaDesktop} from 'react-icons/fa'
import {BiBookContent} from 'react-icons/bi'
import {SiAdobephotoshop} from 'react-icons/si'
import {FaSearch} from 'react-icons/fa'
import {BsGlobe} from 'react-icons/bs'
import { FaRobot } from "react-icons/fa";
import { SiPowerbi } from "react-icons/si";
import { FaDatabase } from "react-icons/fa";




const data = [
    
    {
        id: 10, icon: <FaRobot/>, title: 'Generative AI   ', desc: "Empower your business with the limitless creativity of generative AI, crafting unique content and solutions tailored to your needs, exclusively from our innovative services."
    },
    {
        id: 11, icon: <SiPowerbi/>, title: 'Power Apps', desc: "Unlock the potential of your business with our tailor-made Power Apps solutions, empowering seamless productivity and efficiency at every step."
    },
    {
        id: 3, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Your product will look good and will be accessible on all devices, including mobile phones, tablets, and desktop."
    },
    {
        id: 4, icon: <FaServer/>, title: 'Backend Development', desc: "The security of your business/product is taken seriously right from the start of the project. We will make sure your website/app is secure from attacks."
    },
    {
        id: 5, icon: <AiFillAppstore/>, title: 'Mobile App Development', desc: 'Unlike other developers in the market, we build an app that runs on both iOS and Android devices without any extra cost to you.'
    },
    {
        id: 5, icon: <FaDatabase/>, title: 'Data Engineering', desc: 'Crafting seamless data pipelines, we sculpt the architecture of information flow, empowering businesses with precision and agility.'
    },
    {
        id: 1, icon: <BsGlobe/>, title: 'Website Development', desc: "We offer static, dynamic, and customised websites, including eCommerce websites, for various sizes of businesses using the latest tech stack for future customization."
    },
      
    {
        id: 6, icon: <FaDesktop/>, title: 'Desktop App Development', desc: 'Our team provides cross-platform desktop apps that assure high performance and efficiency with network independence, allowing users the convenience of working offline.'
    },
    {
        id: 8, icon: <SiAdobephotoshop/>, title: 'Graphic Designing', desc: "We offer motion posters, creative ads, brochures, and other graphics work. Make your content visually appealing to communicate your messages. All your graphic solutions at a reasonable cost!"
    },
    {
        id: 9, icon: <FaSearch/>, title: 'Digital Marketing', desc: "We connect you with an audience to promote your brand via social media and improve your website's position in search results. We ensure to list your brand higher so more people can see it."
    },
    {
        id: 2, icon: <SiAdobexd/>, title: 'UI/UX Design', desc: "Our designs are modern and intuitive. We use industry-standard rules to make sure your users have fun using your product."
    },
    {
        id: 7, icon: <BiBookContent/>, title: 'Content Writing', desc: "Make your brand recognised through our quality content writing. We write informative and engaging articles to showcase your products and illustrate how your product different from others'."
    }
]


export default data