import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpg'
// import Avatar3 from '../../assets/avatar3.jpg'
// import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.jpg'
import Avatar6 from '../../assets/avatar6.jpg'
import Avatar7 from '../../assets/avatar7.jpg'
import Avatar8 from '../../assets/avatar8.jpg'


const data = [
        {
        id: 1,
        quote: "IntElligence Tech Solutions has been extremely helpful in providing us with the best and most cost-effective eCommerce development services for our website. We hired them owing to their young talent in the industry. These guys lived up to their name.",
        avatar: Avatar6,
        name: 'Abdul Azeez',
        profession: 'IT Manager at Clinica Joelle, Dubai'
        },

        {
            id: 3,
            quote: "The professional relationship with our IntElligence Tech Solutions team has proven to be beneficial beyond our expectations. There is no way we could manage the work volume required to keep our site current without the efforts of IntElligence Tech Solutions. We look forward to a continued successful working relationship with our IntElligence Tech Solutions team and would be comfortable recommending IntElligence Tech Solutions to others.",
            avatar: Avatar8,
            name: 'Mustafa Tanveer',
            profession: 'Co-owner of Naaz Tourism, India'
            },

        {
        id: 2,
        quote: "We could not have done it without IntElligence Tech Solutions! I am pleased with the services IntElligence has provided me to build and modify the design and functionality of our websites. Its website development services helped our online business grow. I would really like to work with IntElligence Tech Solutions again.",
        avatar: Avatar5,
        name: 'Syed Esa Salami',
        profession: 'Business Development Manager at Italin Technologies, Italy'
        },
        
        {
        id: 4,
        quote: "IntElligence Tech Solutions took all our hassles away by offering us the best inventory management system. This software turned out to be the best rental equipment management solution for our customers. The skilled resources allowed us to build, edit, and maintain our software portal while allowing us to focus more on our core activities.",
        avatar: Avatar2,
        name: 'Shaikh Shoeb',
        profession: 'Founder of Smart Scaffoldings And Machineries, India'
        },
        {
        // id: 5,
        // quote: "The experience of working with you guys was fantastic. Not a very large-scale industry, yet I've got a huge amount of experience in development work. I'm grateful",
        // avatar: Avatar3,
        // name: 'Mohammed Noman',
        // profession: 'Assistant Professor at University of East London, England'
        // },
        // {
        id: 6,
        quote: "I was looking for website development and digital marketing services for my business. After surveying many companies, the search ended with IntElligence Tech Solutions. I was really impressed with the way they catered to my requirements. They provided me with cost-effective solutions, and I hope to work with them in the near future",
        avatar: Avatar7,
        name: 'Rahman Siddiqui',
        profession: 'Co-founder and Brand Marketing Expert at Legit Global, India'
        },
        {
        id: 7,
        quote: "Thank you for your support. We truly appreciate your business and look forward to using your service again. Thank you for completing all our requirements as expected. We liked your work and the accuracy of your work. It was a great experience indeed.",
        avatar: Avatar1,
        name: 'Arif Khureshi',
        profession: 'Founder of Stech Solutions, India'
        }
    ]

    export default data