import { useEffect } from 'react'
import HeaderImage from '../../assets/header.svg'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'
import MicrosoftLogo from '../../assets/mspartner-logo.svg'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="fade-in">  
          <img src={HeaderImage} alt="Header Portait" />
          
        </div>
        <div className='microsoft_logo'>
        <img  src={MicrosoftLogo} alt="Microsoft Partner Logo" />
        </div>
        <h3>IntElligence Tech Solutions</h3>
        <p>
        Your tech solution, not just another tech company.<br/><br/>

        You are a click away from building your dream website or web app. Send us the details of your project for a modern, mobile responsive, highly performant website today!
        </p>
        

        <div className="header__cta"  data-aos="fade-up">
          <a href="#contact" className='btn primary'>Let's Talk</a>
          <a href="#portfolio" className='btn light'>Our Work</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header