import AboutImage from '../../assets/about.jpg'
import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import data2 from './data2'
import Card from '../../components/Card'
import './about.css'
import {AiFillLinkedin} from 'react-icons/ai'


const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Us</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
            <p>
            Building projects that our clients love has always been our passion. Being in the IT industry for over 5 years and serving more than 10 happy clients worldwide, we are always motivated to do more!
            </p>
            <p>
            Hi, we are IntElligence Tech Solutions from London, UK. We specialise in the latest technology stack and IT services. We are skilled professionals who efficiently work with Generative AI, Data engineering, Web development, Android and iOS development, Power apps, Desktop application development, Digital marketing, Content writing, Graphic design, etc. 
            </p>     
            {/* <br /><br />
            Our top priority is to get your business online the right way, giving you industry-standard design and all the functionality you need to operate smoothly online. Get in touch today with the details of your project let's get started! Check out our recent work below! */}
                
          {/* <br /><br /> */}
            <p>Why choose us?</p>
          {/* <br />
          <br /> */}
            <p>
            Because we focus on results. For us, it’s all about what adds value for you and your business. Our top priority is getting your business online the right way, with an industry-standard design and all the functionality you need to operate smoothly online. Get in touch today with the details of your project and let's get started! Check out our recent work below!
            </p>
          
                {/* Hi, we are IntElligence from Bangalore, India. I'm a full-stack web developer with a Bachelors degree in Computer Science. My top priority is to get your business online the right way, giving you industry-standard design and all the functionality you need to operate smoothly online. Get in touch today with the details of your project let's get started! Check out my resume below! */}
                
                {/* <a href={CV} download className='btn primary'>Download CV <HiDownload/></a> */}
            </div>
        </div>
        
        {/* <div className="team__cards">
            {
                data2.map(item => (
                    <Card key={item.id} className="team__cards">
                        <span className='team__card-icon'>{item.icon}</span>
                        <h5>{item.title}</h5>
                        <small>{item.desc}</small>
                    </Card>
                ))
            }
        
        </div>  */}

                {/* <div className="team__cards">
                    {
                        
                        data2.map(item => (
                            <Card key={item.id} className="team__card">
                                <div className='team__details'>
                                    <div className='team__avatar'>
                                        <img src={item.avatar} alt="Team Avatar" />
                                    </div >                                     <div className='team__details-info'>
                                        <h5>{item.name}</h5>
                                        <small>{item.position}</small><br />
                                        <small>{item.company}</small><br />
                                        <a className='link' key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                        </a>
                                    </div>
                                </div>
                                    
                            </Card>
                        ))
                    }
                </div> 
         */}
    </section>
  )
}

export default About